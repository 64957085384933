













































































































































































import { Component, Ref, Vue } from "vue-property-decorator";

import HistoricalRevenueNetworkResponseModel from "@/settings/models/networks-management/HistoricalRevenueNetworkResponseModel";
import { AppSection } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import HistoricalRevenueNetworkRequestModel from "@/settings/models/networks-management/HistoricalRevenueNetworkRequestModel";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component
export default class HistoricalRevenueNetwork extends Vue {
  @Ref("form") readonly form!: VueForm;

  readonly AppSection = AppSection;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: this.$lang("appAdmin.networksManagement.tableHeaders.networkName"),
      value: "networkName",
      width: "60%",
    },
    {
      text: this.$lang("appAdmin.networksManagement.tableHeaders.net"),
      value: "net",
      width: "30%",
    },
    {
      text: this.$lang("appAdmin.networksManagement.actions"),
      align: "end",
      value: "actions",
      sortable: false,
      width: "10%",
    },
  ];
  search = "";
  visible = false;
  valid = true;
  localValue = new HistoricalRevenueNetworkRequestModel();
  editingId = 0;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore
      .historicalRevenueNetworkIsLoading;
  }

  get savingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingHistoricalRevenueNetworkInProgress;
  }

  get historicalRevenueNetwork(): Array<HistoricalRevenueNetworkResponseModel> {
    return this.$store.state.networksManagementStore.historicalRevenueNetwork;
  }

  get formTitle(): string {
    return this.editingId
      ? this.$lang(
          "appAdmin.networksManagement.historicalRevenueNetwork.editTitle"
        )
      : this.$lang(
          "appAdmin.networksManagement.historicalRevenueNetwork.newTitle"
        );
  }

  created() {
    this.$store.dispatch("loadHistoricalRevenueNetwork");
  }

  handleClickByTableRow(item: HistoricalRevenueNetworkResponseModel) {
    this.localValue = HistoricalRevenueNetworkRequestModel.of(item);
    this.visible = true;
    this.editingId = item.id;
  }

  handleDeletingItem(id: number) {
    this.$store.dispatch("deleteHistoricalRevenueNetwork", id);
  }

  handleCloseForm() {
    this.visible = false;
    this.resetLocalData();
  }

  async handleSaving() {
    if (!this.form.validate()) {
      return;
    }

    if (this.editingId) {
      await this.$store.dispatch("updateHistoricalRevenueNetwork", {
        id: this.editingId,
        payload: this.localValue,
      });
    } else {
      await this.$store.dispatch(
        "createHistoricalRevenueNetwork",
        this.localValue
      );
    }

    this.visible = false;
    this.resetLocalData();
  }

  resetLocalData() {
    this.editingId = 0;
    this.localValue = new HistoricalRevenueNetworkRequestModel();
    this.form.resetValidation();
  }
}
