var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{class:[
          'text-decoration-none d-flex align-center route-link',
          { dark: _vm.dark } ],attrs:{"to":{
          name: _vm.AppSection.NETWORKS_MANAGEMENT,
          params: {
            id: _vm.applicationId,
          },
        }}},[_c('v-icon',{attrs:{"color":_vm.dark ? 'grey lighten-3' : 'grey darken-3',"size":"20"}},[_vm._v(" mdi-chevron-left ")]),_c('span',{class:[
            'ml-1 text-body-2 grey--text',
            _vm.dark ? 'text--lighten-3' : 'text--darken-3' ]},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.goToNetworksList"))+" ")])],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.historicalRevenueNetwork.title"))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){_vm.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',{staticClass:"align-center mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":_vm.$lang('funnel.search'),"background-color":_vm.dark ? '#1E1E1E' : 'white',"append-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.historicalRevenueNetwork,"loading":_vm.isLoading,"search":_vm.search,"items-per-page":50,"footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
        }},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeletingItem(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("btn.delete")))])])]}}],null,true)})],1)],1),_c('v-navigation-drawer',{staticClass:"elevation-5",attrs:{"fixed":"","right":"","temporary":"","width":"500","height":"100vh"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-toolbar',{staticStyle:{"z-index":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","elevation":"0"},on:{"click":_vm.handleCloseForm}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("btn.close"))+" ")])])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-toolbar',{attrs:{"tag":"footer","bottom":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"block":"","color":"primary","disabled":!_vm.valid || _vm.savingInProgress,"loading":_vm.savingInProgress},on:{"click":_vm.handleSaving}},[_vm._v(" "+_vm._s(_vm.$lang("btn.save"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-form',{ref:"form",staticClass:"px-4 pt-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$lang('appAdmin.networksManagement.tableHeaders.networkName'),"hide-details":"auto","rules":_vm.requiredRule,"dense":"","outlined":""},model:{value:(_vm.localValue.networkName),callback:function ($$v) {_vm.$set(_vm.localValue, "networkName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localValue.networkName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$lang('appAdmin.networksManagement.tableHeaders.net'),"hide-details":"auto","rules":_vm.requiredRule,"dense":"","outlined":""},model:{value:(_vm.localValue.net),callback:function ($$v) {_vm.$set(_vm.localValue, "net", _vm._n($$v))},expression:"localValue.net"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }