import HistoricalRevenueNetworkResponseModel from "./HistoricalRevenueNetworkResponseModel";

export default class HistoricalRevenueNetworkRequestModel {
  constructor(public net: number = 30, public networkName: string = "") {}

  static of(
    item:
      | HistoricalRevenueNetworkRequestModel
      | HistoricalRevenueNetworkResponseModel
  ): HistoricalRevenueNetworkRequestModel {
    return new HistoricalRevenueNetworkRequestModel(item.net, item.networkName);
  }
}
